/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,500;0,700;1,400;1,600;1,700&family=Yeseva+One&display=swap');

.font-yeseva {
    font-family: 'Yeseva One', cursive;
}

.font-merriweather {
    font-family: 'Merriweather Sans', sans-serif;
}

/* Spinner */
.dots-5 {
    width:12px;
    height:12px;
    background: #eb8f72;
    border-radius: 50%;
    box-shadow: 20px 0 #00000022,-20px 0 #eb8f72;
    animation:d5 1s infinite linear alternate;
}
@keyframes d5 {
    0% {box-shadow: 20px 0 #eb8f72,-20px 0 #00000022;background: #eb8f72}
    33%{box-shadow: 20px 0 #eb8f72,-20px 0 #00000022;background: #00000022}
    66%{box-shadow: 20px 0 #00000022,-20px 0 #eb8f72;background: #00000022}
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.hamburger .line{
    width: 20px;
    height: 2px;
    background-color: #111;
    display: block;
    margin: 6px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
  
.hamburger:hover{
    cursor: pointer;
}
  
  /* ONE */
  
  #hamburger-1.is-active .line:nth-child(2){
    opacity: 0;
  }
  
  #hamburger-1.is-active .line:nth-child(1){
    -webkit-transform: translateY(3px) rotate(45deg);
    -ms-transform: translateY(3px) rotate(45deg);
    -o-transform: translateY(3px) rotate(45deg);
    transform: translateY(3px) rotate(45deg);
  }
  
  #hamburger-1.is-active .line:nth-child(3){
    -webkit-transform: translateY(-13px) rotate(-45deg);
    -ms-transform: translateY(-13px) rotate(-45deg);
    -o-transform: translateY(-13px) rotate(-45deg);
    transform: translateY(-13px) rotate(-45deg);
  }


/* product overview svg

.svgHeart {
    fill: red;
} */